
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { ElTable } from "element-plus";
import { ActionsFi } from "@/store/enums/StoreFiEnums";

export default defineComponent({
  name: "purchase-order-details",
  directives: { mask },
  components: {},
  props: {
    id: { required: true },
  },

  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const orderDetails = ref([]);
    const ordercommonData = ref({});
    const multipleTableRef = ref<InstanceType<typeof ElTable>>();

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Purchase Order Details", []);
      getPurchaseOrderDetails();
    });

    async function getPurchaseOrderDetails() {
      let params = { po_ids: [Number(props.id)] };
      await store
        .dispatch(ActionsFi.CUST_GET_PO_DETAILS_BY_IDS_DATA, params)
        .then(({ data }) => {
          debugger;
          orderDetails.value = data;
          ordercommonData.value = {
            poDetailId: data[0]?.po_no,
            poDate: data[0]?.po_date,
          };
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    return {
      orderDetails,
      ordercommonData,
      multipleTableRef,
    };
  },
});
